import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import memberstack from '@/services/memberstack'
import api from '@/services/api'
import { mode } from '@/config'
import getRouter from '@/router'
let pollTimeout

// upon initializing make sure to clear the bq cache if memberstack is not found
if (!window.localStorage.getItem('_ms-mid')) {
  window.localStorage.removeItem('_bq-va')
  window.localStorage.removeItem('_bq-cn')
}

export const useAppStore = defineStore('app', {
  state: () => ({
    member: null,

    // ACCOUNTS
    account: null,
    showOptionalNextStepsDialog: false,
    showProveOwnershipPrompt: false,
    showProveOwnershipDialog: false,
    showProveOwnershipSuccessPopup: false,
    showMyPlanDialog: false,
    showMyReferralsDialog: false,
    selectedRemoveManuallyLink: null,
    billingLoading: false,

    // AGENCIES
    agency: null,
    initializing: null,
    viewAsAccount: null
  }),
  getters: {
    agencyOrAccount() {
      return this.member.customFields.bqcn === 'Agency'
        ? this.agency
        : this.account
    }
  },
  actions: {
    async initialize() {
      posthog.opt_in_capturing()
      if (!this.member) {
        this.member = await memberstack.getCurrentMember().then(({ data }) => data)
        if (!this.member) {
          return posthog.reset()
        }
      }
      const className = this.member.customFields.bqcn || 'Account'
      window.localStorage.setItem('_bq-cn', className)
      await this.pollMemberJson()
      posthog.identify(this.member.id, {
        className,
        objectId: this.member.customFields.bqid,
        name: this.member.customFields.name,
        email: this.member.auth.email,
        stripeCustomerId: this.member.customFields.stripeCustomerId
      })
      // set view as for agency
      if (className === 'Agency' && window.localStorage.getItem('_bq-va')) {
        this.agencyViewAs(window.localStorage.getItem('_bq-va'))
      }
    },
    setMember({ member }) {
      this.member = member
      return this.initialize()
    },
    async updateVersion(version) {
      window.localStorage.setItem('_bq-v', version)
      'serviceWorker' in navigator && await navigator.serviceWorker.getRegistrations()
        .then(registrations => Promise.all(registrations.map(registration => registration.unregister())))
        .catch(error => console.error('Error during service worker unregistration:', error))
      'caches' in window && await window.caches.keys()
        .then(keys => Promise.all(keys.map(key => window.caches.delete(key))))
        .catch(error => console.error('Error during cache deletion:', error))
    },
    setMemberJson(memberJson) {
      // if version changed, delete all caches
      // if (window.localStorage.getItem('_bq-v') === memberJson.$version) {
      //   this.updateVersion(memberJson.$version)
      // }
      if (this.member.customFields.bqcn === 'Agency') {
        this.agency = memberJson
        return
      }
      this.account = memberJson
    },
    setAccountJson(accountJson) {
      this.account = accountJson
    },
    getPollWaitTime() {
      if (!this.agencyOrAccount?.subscription) {
        return 1000 + Math.random() * 1000
      }
      return 3000 + Math.random() * 5000
    },
    async pollMemberJson() {
      clearTimeout(pollTimeout)
      if (!this.member) { return }
      // poll view as account json if agency & view as
      if (this.member.customFields.bqcn === 'Agency' && this.account) {
        await api.post('/account-json').then(this.setAccountJson)
      }
      await memberstack.getMemberJSON()
        .then(({ data }) => data)
        .then(this.setMemberJson)
        .catch(console.error)
      pollTimeout = setTimeout(this.pollMemberJson, this.getPollWaitTime())
    },

    // AUTH
    async login({ email, password }) {
      await memberstack.loginMemberEmailPassword({
        email,
        password
      }).then(({ data }) => data).then(this.setMember)
    },
    async loginWithProvider(provider) {
      await memberstack.loginWithProvider({
        provider
      }).then(({ data }) => data).then(this.setMember)
    },
    async signupWithEmail({ name, email, password }, { bqcn, gclid }) {
      await memberstack.signupMemberEmailPassword({
        email,
        password,
        customFields: { name, mode, bqcn, gclid }
      }).then(({ data }) => data).then(this.setMember)
      mode !== 'production' && console.log('signed up with mode', mode, { bqcn, gclid })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        email,
        eventLabel: 'email'   //variable depends on which button is pressed
      })
      // attempt to send verification email if necessary
      !this.member.verified && await memberstack.sendMemberVerificationEmail()
    },
    async signupWithProvider(provider, { bqcn, gclid }) {
      await memberstack.signupWithProvider({
        provider,
        customFields: { mode, bqcn, gclid }
      }).then(({ data }) => data).then(this.setMember)
      mode !== 'production' && console.log('signed up with mode', mode, { bqcn, gclid })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'sign_up_complete',
        provider,
        eventLabel: 'provider'   //variable depends on which button is pressed
      })
    },
    async checkout(plan, interval) {
      window.location.href = await api.post('/checkout', { plan, interval, redirect: window.location.href })
    },
    async sendMemberVerificationEmail() {
      const { data: { success } } = await memberstack.sendMemberVerificationEmail()
      if (!success) { throw new Error('Failed to send verification email') }
    },
    sendResetPasswordEmail({ email }) {
      return memberstack.sendMemberResetPasswordEmail({ email })
        .then(({ data }) => data)
        .catch((error) => {
          return error?.message
        })
    },
    async resetPassword({ token, password }) {
      const { data: { success } } = await memberstack.resetMemberPassword({
        token,
        newPassword: password
      })
      if (!success) { throw new Error('Invalid token') }
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      window.localStorage.removeItem('_bq-va')
      window.localStorage.removeItem('_bq-cn')
      await memberstack.logout()
      window.location.href = '/'
    },
    async updateName(name) {
      clearTimeout(pollTimeout)
      await api.post('/update-name', { name }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async updateEmail(email) {
      clearTimeout(pollTimeout)
      await api.post('/update-email', { email }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    async updateEmailSubscription(subscribed) {
      clearTimeout(pollTimeout)
      await api.post('/update-email-subscribed', { subscribed }).then(this.setMemberJson)
      this.pollMemberJson()
      return true
    },
    // ACCOUNTS
    async updateNiches(niches) {
      clearTimeout(pollTimeout)
      await api.post('/update-niches', { niches }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async addLink(link) {
      clearTimeout(pollTimeout)
      await api.post('/add-link', { link }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async removeLink(linkId) {
      if (this.account.links.length === 1) {
        return alert('You must have at least one link. Please add another link before removing this one.')
      }
      if (!confirm('Are you sure you want to remove this link?')) { return }
      clearTimeout(pollTimeout)
      await api.post('/remove-link', { linkId }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async addUsername(username) {
      clearTimeout(pollTimeout)
      await api.post('/add-username', { username }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async removeUsername(usernameId, dismissingRejected) {
      if (!dismissingRejected) {
        if (!confirm('Are you sure you want to remove this username?')) { return }
        // TODO check if platform username
        if (this.account.usernames.length === 1) {
          return alert('You must have at least one username. Please add another username before removing this one.')
        }
      }
      clearTimeout(pollTimeout)
      await api.post('/remove-username', { usernameId }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async confirmNoReddit() {
      clearTimeout(pollTimeout)
      await api.post('/confirm-no-reddit').then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async confirmNoTwitter() {
      clearTimeout(pollTimeout)
      await api.post('/confirm-no-twitter').then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async addSafelist(domain) {
      if (await api.post('/get-whitelisted', { domain })) {
        return 'whitelisted'
      }
      clearTimeout(pollTimeout)
      await api.post('/add-safelist', { domain }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async removeSafelist(domain) {
      if (!confirm(`Are you sure you want to remove ${domain} from your safelist?`)) { return }
      clearTimeout(pollTimeout)
      await api.post('/remove-safelist', { domain }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async dismissOptionalNextSteps() {
      clearTimeout(pollTimeout)
      await api.post('/dismiss-optional-next-steps').then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async dismissProveOwnershipSuccessPopup() {
      clearTimeout(pollTimeout)
      await api.post('/dismiss-verify-ownership-success-popup').then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async uploadFile(file, scope) {
      if (!['id_front', 'selfie_with_id'].includes(scope)) {
        throw new Error('Invalid scope')
      }
      clearTimeout(pollTimeout)
      const form = new FormData()
      form.append('file', file)
      await api.post('/upload-file', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-File-Scope': scope
        }
      }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async deleteFile(scope) {
      if (!['id_front', 'selfie_with_id'].includes(scope)) {
        throw new Error('Invalid scope')
      }
      return await api.post('/delete-file', { scope })
    },
    async getFile(scope) {
      if (!['id_front', 'selfie_with_id'].includes(scope)) {
        throw new Error('Invalid scope')
      }
      return await api.post('/get-file', { scope })
    },
    async submitIdentity({ id_front, selfie_with_id }) {
      clearTimeout(pollTimeout)
      await api.post('/identity', { id_front, selfie_with_id }).then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async removeIdentity() {
      clearTimeout(pollTimeout)
      await api.delete('/identity').then(this.setAccountJson)
      this.pollMemberJson()
      return true
    },
    async fetchLeaksFound({ filters, pagination, skip }) {
      const hasNonDefaultFilters = Object.keys(filters).some(key => filters[key] !== null)
      // the leaksFoundCache -> has up to 50 items of the /leaks-found results
      const isOutsideCachedRange = (skip + pagination) > this.account.stats?.leaksFoundCache?.length
      if (hasNonDefaultFilters || isOutsideCachedRange) {
        return await api.post('/leaks-found', { filters, pagination, skip })
      }
      return {
        results: (this.account.stats?.leaksFoundCache || []).slice(skip, skip + pagination),
        count: this.account.stats?.found || 0
      }
    },
    async fetchImpersonatorsSuspected({ filters, pagination, skip }) {
      return await api.post('/impersonators-suspected', { filters, pagination, skip })
    },
    async decideImpersonatorResult(objectId, action) {
      clearTimeout(pollTimeout)
      const response = await api.post('/impersonator-action', { objectId, action })
      await this.pollMemberJson()
      return response
    },
    async getTakedownRequests({ skip }) {
      return await api.post('/takedown-requests', { skip })
    },
    async submitTakedownRequest(form) {
      return await api.post('/submit-takedown-request', form)
    },
    async fetchDomainDmcaContact(link) {
      return await api.post('/domain-dmca-contact', { link })
    },
    async checkVerification() {
      clearTimeout(pollTimeout)
      await api.post('/verification-check').then(this.setAccountJson)
      if (this.account.verifiedAt) {
        this.showProveOwnershipPrompt = false
        this.showProveOwnershipSuccessPopup = true
      }
      this.pollMemberJson()
    },
    async startScan() {
      clearTimeout(pollTimeout)
      await api.post('/start-scan').then(this.setAccountJson)
      this.pollMemberJson()
    },
    setOptionalNextStepsDialog(value) {
      this.showOptionalNextStepsDialog = value
    },
    setProveOwnershipPrompt(value) {
      this.showProveOwnershipPrompt = value
    },
    setProveOwnershipDialog(value) {
      this.showProveOwnershipDialog = value
      this.showProveOwnershipPrompt = false
      this.showOptionalNextStepsDialog = false
    },
    setRemoveManuallyLink(item) {
      this.selectedRemoveManuallyLink = item
    },
    setMyReferralsDialog(value) {
      this.showMyReferralsDialog = value
    },
    setMyPlanDialog(value) {
      this.showMyPlanDialog = value
    },

    // AGENCIES
    async agencyViewAs (accountId) {
      this.initializing = accountId
      window.localStorage.setItem('_bq-va', accountId)
      this.account = await api.post('/account-json')
      this.initializing = null
    },
    async exitAgencyViewAs () {
      const router = await getRouter()
      router.push({ name: 'index' })
      this.account = null
      window.localStorage.removeItem('_bq-va')
    },
    async addCreator (name) {
      await api.post('/agency-add-creator', { name }).then(this.setMemberJson)
    },
    async changePlan(accountId, plan) {
      await api.post('/agency-change-plan', { accountId, plan }).then(this.setMemberJson)
    },
    async cancelPlanChange(accountId) {
      await api.post('/agency-cancel-plan-change', { accountId }).then(this.setMemberJson)
    },
    async agencyCheckout() {
      const session = await api.post('/agency-checkout', { redirect: window.location.href })
      if (session && session.url) {
        window.location.href = session.url
        return
      }
      window.location.href = window.location.href + '#success-pending'
      window.location.reload()
    },
    async getPendingSubscriptionUpdate() {
      return this.agency.pendingSubscriptionUpdates
        ? await api.post('/agency-preview-subscription-update')
        : null
    },
    async applyPlanChanges() {
      await api.post('/agency-apply-plan-changes').then(this.setMemberJson)
    },
    async discardPlanChanges() {
      await api.post('/agency-discard-plan-changes').then(this.setMemberJson)
    },
    async removeCreator(accountId) {
      await api.post('/agency-remove-creator', { accountId }).then(this.setMemberJson)
    },
    async goToBilling() {
      if (!this.agencyOrAccount.stripeCustomerId) {
        throw new Error('No billing information found. Please contact us.')
      }
      this.billingLoading = true
      try {
        const billingUrl = await api.post('/stripe-billing-portal-url', {
          customer: this.agencyOrAccount.stripeCustomerId,
          redirect: window.location.href
        })
        window.location.href = billingUrl
      } catch (error) {
        this.billingLoading = false
        throw new Error('Failed to get billing portal URL to Stripe. Please contact us.')
      }
    },
  },
})
